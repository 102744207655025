@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes blink {
  0%, 100% {
    opacity: 0.2;
    transform: translateY(0); /* Dots at the initial position */
  }
  50% {
    opacity: 1;
    transform: translateY(-5px); /* Dots move up by 5px */
  }
}

.typing-dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background: white;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.2;
  animation: blink 1.4s infinite ease-in-out;
}

.typing-dot:nth-child(1) {
  animation-delay: 0s;
}

.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes rotate180 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

html {
  scrollbar-width: thin;
  scrollbar-color: #363941 #fff;
}

.scrollbar-div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollbar-div::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-div::-webkit-scrollbar-thumb {
  background-color: #363941;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.scrollbar-div::-webkit-scrollbar-thumb:hover {
  background-color: #8598b4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
